import React from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  StatHelpText,
  Box,
  Container,
  Flex,
  Heading,
  Text,
  Link,
  Stat,
  LinkBox,
  LinkOverlay
} from "@chakra-ui/react";
import { LocalizedLink as GLink } from "gatsby-theme-i18n";
import { useIntl } from "react-intl";
import { Slug } from "../types/types";
// import { keyframes } from '@emotion/react'

// const raise = keyframes({ from: { opacity: 0 }, to: { opacity: 1 } })


/**
 * @returns Renders a resource card.
 * @props description: string;
 * @props title: string;
 * @props date: string;
 * @props to?: Slug;
 * @props urlOnlyResource?: string;
 * }
 */

export function ResourceCard({
  description,
  title,
  date,
  to,
  urlOnlyResource,
  no,
}: ResourceProps) {
  const intl = useIntl();

  return (
    <LinkBox
      borderBottom="gray"
      key={to}
      bg={"contrast"}
      shadow='base'
      // transitionTimingFunction='ease-in-out'
      _hover={{ boxShadow: "lg" }}
      borderRadius="xl"
      p="2rem"
    >
      <Flex
        justifyContent="space-between"
        flexDir={["column", "row"]}
        alignItems="center"
        width="100%"
      >
        <Container maxW="100%">
          <Stat>
            <StatHelpText fontSize={'sm'} mt="0.5rem" color={'contrastDark'}>
              Marginalie #{no} vom {date}
            </StatHelpText>
          </Stat>
          <Heading my='1rem' fontSize="2xl">
            <LinkOverlay as={GLink} to={to} >
              {title}
            </LinkOverlay>
          </Heading>
          <Text fontSize={'md'}>{description}</Text>
        </Container>
        <ChevronRightIcon color="accent" boxSize="10" />
      </Flex>
    </LinkBox>
  );
}

interface ResourceProps {
  description: string;
  title: string;
  date: string;
  to?: Slug;
  no: string;
  urlOnlyResource?: string;
}
