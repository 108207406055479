import React from "react";

import SEO from "../globals/seo";
import { ResourceCard } from "../globals/resourceCard";
import {
  Container,
  List,
  ListIcon,
  ListItem,
  OrderedList,
  VStack,
} from "@chakra-ui/react";

const IndexPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMdx.nodes;

  return (
    <>
      <SEO />
      <Container maxW={"3xl"}>
        <VStack my="2rem" alignItems={"stretch"}>
          {posts.map((post, i) => {
            const title = post.frontmatter.title || post.slug;
            const { slug } = post;
            const { date, description, urlOnlyResource } = post.frontmatter;
            return (
              <ResourceCard
                no={`${posts.length - i}`.padStart(2, '0')}
                title={title}
                key={slug}
                to={slug}
                urlOnlyResource={urlOnlyResource}
                description={description}
                date={date}
              />
            );
          })}
        </VStack>
      </Container>
    </>
  );
};

export default IndexPage;
