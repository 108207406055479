import React, { useEffect } from "react";
import { graphql, navigate } from "gatsby";
import IndexPage from "../components/wrapper/IndexPage";

const Index = ({ data }) => {
  return <IndexPage data={data} />;
};

export default Index;

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { slug: { regex: "/resources/" } }
    ) {
      nodes {
        excerpt
        frontmatter {
          date(formatString: "DD MMMM, YYYY", locale: "de")
          title
          description
        }
        slug
      }
    }
  }
`;
